<template>
  <div class="service-flow">
    <div class="title">サービスの流れ</div>
    <div class="flow-explain">
      <div class="step">
        <p class="step-number">Step 1.</p>
        <img src="@/assets/oem-service-flow-1.png">
        <p class="step-title">お問い合わせ</p>
        <p class="detail">輸入したい商品のURL(アリババ・タオバオのサイトURLを添付しご連絡ください)</p>
      </div>
      <div class="step">
        <p class="step-number">Step 2.</p>
        <img src="@/assets/oem-service-flow-2.png">
        <p class="step-title">折り返し連絡</p>
        <p class="detail">弊社基準により輸入代行の可否をご連絡いたします</p>        
      </div>
      <div class="step">
        <p class="step-number">Step 3.</p>
        <img src="@/assets/oem-service-flow-3.png">
        <p class="step-title">輸入可否確認</p>
        <p class="detail">仕入れ（輸入）可能な場合は担当者よりエクセルのシートを送りますので必要な部分を記入し返信をお願いします</p>        
      </div>
      <div class="step">
        <p class="step-number">Step 4.</p>
        <img src="@/assets/oem-service-flow-4.png">
        <p class="step-title">仕入れ確認</p>
        <p class="detail">内容のご確認をいただき商品代金のご入金後輸入代行の手続きを行います</p>        
      </div>                  
    </div>
    <div class="inquiry align-center mt-8">
      <button 
        @click="toContact"
        class="btn-inquiry">お問い合わせはこちら</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceFlowsOem',
  props: {
    background: String,
  },
  methods: {
    toContact() {
      location.href = '/contact'
    }
  }
}
</script>

<style lang="scss" scoped>
.service-flow {
  padding-bottom: 160px;
  background: white;
  .title {
    padding: 80px 0px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
  }
  .flow-explain {
    display: flex;
    width: 90%;
    margin: auto;
    .step {
      flex: 1;
      margin: 0 10px;
      text-align: center;
      padding: 10px 15px;
      border-radius: 5px;
      // box-shadow: 1px 16px 10px -14px rgba(0, 0, 0, 0.35);
      img {
        width: 180px;
      }
      p {
        margin: 10px 0px;
      }
      .step-number {
        font-size: 35px;
        color: #F2B504;
        font-weight: bold;
      }
      .step-title {
        font-weight: bold;
      }
      .detail {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .service-flow {
    .flow-explain {
      flex-direction: column;
      .step {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
