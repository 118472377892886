<template>
  <service-menu current="oem"/>
  <div class="service-top">
    <div class="content">
      <h1>OEMサービス</h1>
      <p>
        工場との直接のやり取りは弊社の方でサポートさせていただきます。<br>
        ロゴ入れ、パッケージ制作など簡易なものから、仕様変更、カスタマイズなどのオリジナルまでサポートいたします。
      </p>
    </div>
  </div>
  <div class="issues">
    <h1>OEMで自社商品を販売しましょう</h1>
    <div class="row pc">
      <div class="title bg-grey">
        転売するセラーが抱える課題
      </div>
      <div class="center-space"></div>
      <div class="title bg-yellow">
        OEMで自社商品を販売するメリット
      </div>
    </div>
    <div v-for="issue in issues" :key="issue.question" class="row pc">
      <div class="left-text">
        {{issue.question}}
      </div>
      <div class="center-connector">
        <span class="circle-icon"></span>
        <span class="dot-line"></span>
        <span class="arrow-left"></span>
      </div>
      <div class="right-text">
        {{issue.solution}}
      </div>
    </div>
    <div class="sp">
      <div class="box bg-grey">
        <div class="title-grey">転売するセラーが抱える課題</div>
        <div>
          <p v-for="issue in issues" :key="issue.question">{{issue.question}}</p>
        </div>
      </div>
      <div class="center-connector">
        <span class="circle-icon"></span>
        <span class="dot-line"></span>
        <span class="arrow-down"></span>
      </div>      
      <div class="box bg-orange">
        <div class="title-orange">転売するセラーが抱える課題</div>
        <div>
          <p v-for="issue in issues" :key="issue.question">{{issue.question}}</p>
        </div>
      </div>      
    </div>
  </div>
  <div class="services">
    <h1>サービス内容</h1>
    <div class="detail">
      <div 
        v-for="service in services" 
        :key="service.id" class="service">
        <img :src="require(`@/assets/oem-service-${service.id}.jpg`)">
        <p>{{service.name}}</p>
      </div>
    </div>
  </div>
  <service-flows-oem />
  <div class="faqs">
    <h1>よくあるご質問</h1>
    <faq
      v-for="(faq, index) in faqs"
      :key="index"
      :question="faq.question"
      :answer="faq.answer"
      />
  </div>
</template>

<script>
import Faq from '@/components/atoms/Faq.vue'
import ServiceMenu from '@/components/molecules/ServiceMenu.vue'
import ServiceFlowsOem from '@/components/molecules/ServiceFlowsOem.vue'

export default {
  name: 'Service',
  components: {
    Faq, ServiceMenu, ServiceFlowsOem,
  },  
  data() {
    return {
      issues: [
        {question: 'すぐに相乗りされ、価格競争になる', solution: '完全ブルーオーシャン、競争が少ない'},
        {question: '利益率が低い', solution: '利益率が高い'},
        {question: '商品選定などのやり方がわからない', solution: 'SellerPortが手厚くサポートするので安心'},
        {question: '都度入荷など、リサーチが必要', solution: '継続注文するだけで回転する'},
        {question: '手続きが煩雑で非常に時間がかかる', solution: '面倒な作業は全て丸投げできるので楽'},                                
      ],
      services: [
        {id: 1, name: '商品撮影'},
        {id: 2, name: 'モデル撮影'},
        {id: 3, name: '布タグ制作'},
        {id: 4, name: '紙タグ制作'},
        {id: 5, name: '商品のデザイン変更'},
        {id: 6, name: 'ロゴ入り袋制作'},
        {id: 7, name: 'ロゴ入りシール制作'},
      ],
      faqs: [
        {
          question: '日本からサンプルで商品を送る事は出来ますか？',
          answer: '可能です。日本から中国へ大量に商品を送ると販売目的とみなされて、通関出来ない可能性がありますので、ご注意頂ければ幸いです。サンプルのみなら問題ございません。',
        },
        {
          question: '紙タグ・布タグ作成は可能でしょうか？',
          answer: 'PDFでデータを頂ければ作成可能です。'
        },
        {
          question: 'ロットはどれくらいですか？',
          answer: 'おおよその目安としてSKU100pcsをお考え下さい。'
        }
      ]  
    }
  },
}
</script>

<style lang="scss" scoped>
.service-top {
  background: url('../assets/oem-top.png');
  background-size: cover;
  background-repeat: round;
  height: 350px;
  .content {
    background: rgba(51,51,51,0.78);
    height: 100%;
    padding-left: 8%;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h1 {
      border-bottom: 1px solid white;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}
.services {
  background: #F7F7F7;
  text-align: center;
  padding: 50px 0 40px 0;
  h1 {
    padding: 30px 0 50px 0;
  }
  .detail {
    display: inline;
  }
  .service {
    display: inline-flex;
    width: 22%;
    flex-direction: column;
    padding: 0 10px;
    align-items: center;
    margin-bottom: 50px;
    font-weight: bold;
    img {
      width: 70%;
    }
  }
}
.issues {
  text-align: center;
  padding: 30px 20px;
  font-weight: bold;
  margin: auto;
  .row {
    display: flex;
    justify-content: center;
    padding: 20px;
    .title {
      display: inline-flex;
      width: 28%;
      padding: 10px 20px;
      font-weight: bold;
      color: white;
      justify-content: center;
      align-items: center;
    }
    .bg-grey {
      background-color: #606060;
    }
    .bg-yellow {
      background: #F2B404;
    }    
    .center-space {
      display: inline-flex;
      width: 20%;
    }
    .left-text {
      display: inline-flex;
      width: 30%;
      color: black;
    }
    .right-text {
      display: inline-flex;
      width: 30%;
      color: #F2B404;
      padding-left: 20px;
    }
    .center-connector {
      width: 20%;
      // padding: 0 30px;
      // margin: 0 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .circle-icon {
        border: 2px solid #999999;
        height: 10px;
        width: 10px;
        display: block;
        border-radius: 7px;
      }
      .dot-line {
        border-top: 3px dotted #999999;
        width: 80%;
      }
      .arrow-left {
        border: solid #999999;
        border-width: 0 2px 2px 0;
        padding: 6px;
        margin-left: -10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);      
      }
    }
  }
}
.flows {
  background: #F7F7F7;
  padding: 30px 0px;
  h1 {
    text-align: center;
    margin-top: 0;
  }
  .box {
    background-color: #F7F7F7;
    width: 80%;
    margin: auto;
    padding: 30px 20px 50px 20px;
    .row {
      display: flex;
      justify-content: center;
      .supplier {
        width: 220px;
        height: 220px;
        border-radius: 110px;
        background-color: white;
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 210px;
          margin-left: -100px;
          font-size: 22px;          
        }
      }
      .connector-left {
        transform: rotate(115deg);
        background: white;
        width: 30px;
        margin-top: -40px;
      }
      .connector-right {
        transform: rotate(60deg);
        background: white;
        width: 30px;
        margin-top: -40px;
        opacity: 0;   
      }      
      .connector-left-down {
        transform: rotate(60deg);
        background: white;
        width: 30px;
        margin-top: -100px;
      }
      .connector-right-down {
        transform: rotate(115deg);
        background: white;
        width: 30px;
        margin-top: -100px;
        opacity: 0; 
      }         
      .center {
        width: 300px;
        height: 300px;
        border-radius: 150px;
        background: white;
        margin-top: 125px;
        display: flex;
        align-items: center;
        justify-content: center;     
        position: relative;
        img {
          width: 85%;
        }
      }
      .center-gray {
        width: 300px;
        opacity: 0;
      }
      .airplane {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        background-color: white;
        margin-top: 65px;
        margin-left: 30px;
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: -190px;
          margin-right: -180px;
          font-size: 22px;
          position: absolute;    
        }
      }
      .ship {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        background-color: white;
        margin-top: -65px;
        margin-left: 30px;        
        label {
          z-index: 2;
          font-weight: bold;
          color: #F2B504;
          background: white;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 190px;
          margin-right: -180px;
          font-size: 22px;    
        }        
      }
      .supplier, .airplane, .ship {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;        
        img {
          position: absolute;
          z-index: 1;
          width: 90%;
        }
      }   
    }
    .row-down {
      margin-top: -80px;
    }
  }
}
.features {
  background-color: #F2B504;
  color: white;
  text-align: center;
  padding: 20px 0 50px 0;
  font-weight: bold;
  h1 {
    margin: 0;
    padding: 30px 0;
  }
  .circles {
    display: flex;
    justify-content: center;
    .circle {
      width: 160px;
      height: 160px;
      border: 2px solid white;
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 20px;
      span {
        display: block;
      }
    }
  }
}
.faqs {
  background: #F7F7F7;
  h1 {
    text-align: center;
  }
}

@media (min-width: 751px) {
  .plan {
    table {
      width: 70%;
      margin: auto;
      padding: 3px;
      td {
        border: solid 5px #F7F7F7;
        padding: 0px;
        text-align: center;
      }
      td {
        padding: 10px;
        background: white;
      }
      .category {
        background: #606060;
        color: white;
        text-align: center;
        font-weight: bold;
      }
      .transparent {
        background: transparent;
      }    
    }
  }
}

@media screen and (max-width: 750px) {  
  .service-top {
    background: url('../assets/oem-top-sp.png');
    background-size: cover;
  }
  .customer-voice {
    .customer-voice-row {
      flex-direction: column;
      .voice:not(:nth-child(1)) {
        margin-top: 30px;
      }
    }
  }
  .service-flows {
    dl {
      flex-direction: column;
      align-items: center;
      dh {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: 23px;
        padding-left: 15px;
      }
      dd {
        margin-inline-start: 0;
        img {
          height: 120px;
        }
      }
      dd:last-child {
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
  .plan {
    .title {
      background-color: #F2B504;
      color: white;
      text-align: center;
      font-weight: bold;
      width: 60%;
      margin: auto;
      border-radius: 5px;
      padding: 10px 0;
      position: relative;
      margin-top: 50px;
      &::before {
        content: "";
        border-top: 30px solid #F2B404;
        border-right: 30px solid transparent;
        border-left: 30px solid transparent;
        position: absolute;
        top: 30px;
      }      
    }
    .service-block {
      background-color: white;
      width: 85%;
      padding: 5px 5px 30px 5px;
      margin: 20px auto;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
      h3 {
        margin-bottom: 0;
      }
      .subtitle {
        font-weight: bold;
        text-align: center;
      }
      table {
        text-align: center;
        width: 90%;
        margin-top: 10px;
      }
    }
  }
  .issues {
    h1 {
      font-size: 21px;
      margin-bottom: 50px;
    }
    .box {
      width: 85%;
      margin: auto;
      padding: 40px 10px 20px 10px;
      position: relative;
      .title-grey {
        color: white;
        background: #606060;
        top: -20px;
        width: 80%;
        padding: 10px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
      .title-orange {
        color: white;
        background: #F2B504;
        top: -20px;
        width: 80%;
        padding: 10px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }      
      p {
        font-weight: normal;
      }
    }
    .bg-grey {
      border: 1px solid #C4C4C4;
    }
    .bg-orange {
      border: 1px solid #F2B504;
    }
    .center-connector {
      width: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;
      .circle-icon {
        border: 3px solid #999999;
        height: 20px;
        width: 20px;
        display: block;
        border-radius: 14px;
        margin-top: -25px;
        background: white;
        z-index: 10;
      }
      .dot-line {
        border-left: 4px dotted #999999;
        width: 3px;
        height: 60px;
        margin-left: 3px;
      }
      .arrow-down {
        border: solid #999999;
        border-width: 0 3px 3px 0;
        padding: 6px;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: 15px;
        margin-top: -10px;
      }
    }    
  }
  .services {
    .service {
      width: 42%;
    }
  }
}
</style>